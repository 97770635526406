import styled from 'styled-components';

const ItemStyles = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border:1px solid #ececec;
  background: #ececec;
  padding: 1rem;

  .item {
    vertical-align: middle;
    padding-bottom: 10px;
  }

  input {
    max-width: 40px;
  }

  p {
    margin: 0;
  }

  label {
    display: block;
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 10px;
    span {
      background: #FFF;
      padding: 4px;
      margin-left: 10px;
    }
  }
  
  button {
    margin-left: 5px;
    font-size: 1rem;
  }

  button + button {
    margin-left: 1rem;
  }
  .remove {
    background: none;
    color: var(--red);
    font-size: 3rem;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none;
    line-height: 1rem;
  }
  
  @media (max-width: 900px) {
      font-size: 1rem;
      label {
        font-size: 1.2rem;
      }
  }
`;

export default ItemStyles;