import { useState, useContext } from 'react';
import InventoryOrderContext from '../components/InventoryOrderContext';

export default function useRequest({items, values}) {

	// Now we access both our state and our updater function (setOrder) via context
	const [inventoryOrder, setInventoryOrder] = useContext(InventoryOrderContext);
	//
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');

	// Add Item to Request Order	
	function addToRequest(e, requestedItem) {
		e.preventDefault();
		console.log(requestedItem);

		var i;
		for (i = 0; i < inventoryOrder.length; i++) {
			if (inventoryOrder[i].id === requestedItem.id) {
				setError("Item Already Added");
				return true;
			}
		}

		setInventoryOrder([...inventoryOrder, requestedItem]);		
	  }


	// Remove Items from Order
	function removeFromRequest(index) {	
		setError("");
		setInventoryOrder([
			// everything before
			...inventoryOrder.slice(0, index),
			// everything after
			...inventoryOrder.slice(index + 1),
		]);
	}

	// function is run when the form is submitted
	async function submitOrder(e) {
		e.preventDefault();
		setLoading(true);
		setError(null);

		// Gather all the data
		const body = {
			request: inventoryOrder,
			employee: values.employee,
			mapleSyrup: values.mapleSyrup,
		};

		// 4. Send data to a serverless function when they checkout.
		const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/hello`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
		
		const text = JSON.parse(await res.text());
    
		
		// check if everything worked
		if((res.status >= 400 && res.status < 600)) {
			setLoading(false); //turn off loading
			setError(text.message);
		} else {
			setLoading(false); //turn off loading
			setMessage('Success!');
			//const rebuild = await fetch('https://api.netlify.com/build_hooks/5f7c171afe77cf523ee8965d', {method: 'POST', body: 'a=1'});
		}
	}

	return {
		inventoryOrder,
		error,
		loading,
		message,
		removeFromRequest,
		addToRequest,
		submitOrder
	};
}