
import styled from 'styled-components';

const OrderStyles = styled.form`
  .items-fieldset {
    position: relative;
    max-height: 35vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--primary) var(--white);
    &::-webkit-scrollbar {
      width: 20px;
    }
    &::-webkit-scrollbar-track {
      background: var(--white);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary) ;
      border-radius: 6px;
      border: 3px solid var(--red);
    }
  }
  .error {
    color: var(--red);
    p { margin-top: 0;}
  }
  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: auto;
    gap: 1rem;
    align-content: start;
    max-width: 100%;
    label { 
      padding-right: 10px;
    }
    > div {      
      display: flex;
      justify-content: space-between;
      width: auto;
      padding: 0;
    }
    label {
      padding: 5px;
      margin: 0;
    }
  }
  .add-button {
    border: 1px solid #CCC;
    min-width: 95px;
    padding: 5px;
  }
  .order {
    display: grid;
    grid-template-columns: 100%;
    overflow: auto;
    gap: 1rem;
    align-content: start;
    > div {
      background: var(--primary);
      color: #FFF;
      span {
        margin-left: 10px;
        border: 1px solid #FFF;
        padding: 5px;
        border-radius: 50%;
        height: 20px;
        widht: 20px;
      }
    }
  }
  .mapleSyrup {
    display: none;
  }
  @media (max-width: 768px) {
    legend {
      font-size: 1.4rem;
      color: var(--primary)
    }
    .items {
      grid-template-columns: repeat(1, 1fr);
      gap: 5px;
    }
  }
`;

export default OrderStyles;