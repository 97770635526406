import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import useRequest from '../utils/useRequest'
import useForm from '../utils/useForm'

import OrderStyles from '../styles/OrderStyles'
import ItemStyles from '../styles/ItemStyles'

// Airtable Credentials (ORGANIZE INTO GLOBAL STATE - MAKE DRY)
var Airtable = require('airtable');
Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'keyb3cmdgRSQ6bCN3'
});
var base = Airtable.base('appj3glVzELKCV6vo');


export default function RequestPage({data}) {

  // Only using GraphQL for employees, might as well filter in GraphQL
  const items = data.allAirtable.edges;
  
  const [inventoryList, setInventoryList] = useState([]);
  const [prevInventoryList, setPrevInventoryList] = useState([]);
  
	// trying to change stock numbers on add or remove items
	const [currentItemStock, setCurrentItemStock] = useState(0);
   
  // ----------------------
  // RUNTIME DATA FETCHING: All Current Inventory Items
  // ----------------------
  useEffect(() => {
    base('Inventory').select({
      maxRecords: 100,
      fields: ["Name", "Current Stock"]
    }).eachPage(function page(records) {
      
      // Records Check
      // records.forEach(function(record) {
      // 	console.log('Retrieved', record.get('Name'));
      // });
  
      // Get all Inventory Items, filter any Name-less entries and sort alphabetically.
      const allItems = records.map((record) => {
        return {
          id: record.id,
          name: record.get('Name'),
          stock: record.get('Current Stock')
        };
      }).filter(function(item){
        return item.name !== undefined;
      }).sort((a, b) => a.name.localeCompare(b.name));
      
      setInventoryList(allItems);
      setPrevInventoryList(allItems);
  
      }, function done(err) {
        if (err) { console.error(err); return; }
      });
  }, []);

  
  const inventoryItems = inventoryList.map((item) => {
      return {
          id: item.id,
          name: item.name,
          stock: item.stock
        }
    });

  
  // Pass the forms values to updateValue
  const { values, updateValue } = useForm({
    employee: '',
    mapleSyrup: '',
  });
  
  const {
    inventoryOrder,
    error,
    loading,
    message,
    removeFromRequest,
    addToRequest,
    submitOrder
  } = useRequest({
        items,
        values
    });
  
    // Once a message is recieved, display success or error.
  if (message) {
    return <p>{message}</p>;
  }
    
  const handleUpdate = (e, item, index) => {

    setCurrentItemStock(e.target.value);
    
    const newItemsList = [...inventoryItems];
    let newStock = newItemsList[index].stock - e.target.value;
    
    // Check if target value is negative.
    if ( e.target.value <= 0 ) {
      e.target.value = 0;
      newStock = newItemsList[index];
    }
    
    // If form value is greater than current stock quantity.
    if ( e.target.value > prevInventoryList[index].stock ) {
      e.target.value = prevInventoryList[index].stock;
      newStock = 0;
      setCurrentItemStock(prevInventoryList[index].stock);
    }

    newItemsList[index].stock = prevInventoryList[index].stock - e.target.value;
    setInventoryList([...newItemsList]);  
};

  // Employee List for Form Select field.
  const listEmployees = items.map((employee, index) => {

    if (employee.node.table === "Employees") {
      if (employee.node.data.Name === 'Dalton Knox' || employee.node.data.Name === 'Dominic Irace' || employee.node.data.Name === 'Voyager Test' ) {
        return '';
      } else {
        return <option key={employee + index} value={employee.node.recordId}>{employee.node.data.Name}</option>
      }
    }

    return '';
  });

  return (
    <div>
      <OrderStyles onSubmit={submitOrder} netlify-honeypot="mapleSyrup" data-netlify="true">

        <input
          type="mapleSyrup"
          name="mapleSyrup"
          id="mapleSyrup"
          value={values.mapleSyrup}
          onChange={updateValue}
          className="mapleSyrup" />

        {/* Employee Information */}
        <fieldset>
          <legend>Requester</legend>
          <select
            name="employee"
            id="employee"
            value={values.employee}
            onChange={updateValue}
          >
            <option>Choose Here</option>
            {listEmployees}
          </select>
        </fieldset>

        {/* All Items */}
        <fieldset className="items-fieldset">
          <legend>Add Items to Request</legend>
          <div className="items">            
          {inventoryList.map((item, index) => {
              return (
                <ItemStyles key={index}>
                  <label htmlFor={item.name}>
                    {item.name} 
                    <span>{item.stock}</span>
                  </label>
                  <div className="add-button">
                    <input
                      type="number"
                      name={item.name}
                      placeholder="0"
                      onChange={(e) => handleUpdate(e, item, index)}
                      />

                    <button onClick={(e) => {
                      addToRequest(e, {
                        id: item.id,
                        fields: {
                          Name: item.name,
                          Stock: item.stock,
                          Requested: Number(currentItemStock)
                        }
                      });
                      setCurrentItemStock(0); 
                    }}>Add</button>
                  </div>
                </ItemStyles>
              )
            })}
          </div>
        </fieldset>


          {/* // THE REQUESTED ITEMS */}
          <fieldset>
            <legend>Items Request</legend>
            <div className="order">

            { inventoryOrder.map((item, index) => {
                return (
                  <ItemStyles key={index}>
                    <h2>
                      {item.fields.Name} 
                      <span>{item.fields.Requested}</span>
                    </h2>
                    <button
                      type="button"
                      className="remove"
                      title={`Remove ${item.fields.Name} from Order`}
                      onClick={() => removeFromRequest(index)}
                     > &times; </button>
                  </ItemStyles>
                )
              })
            }
            <p><small>You have {inventoryOrder.length} products in your order.</small></p>
            </div>
          </fieldset>

        <fieldset>
          <div className="error">{error ? <p>Error: {error}</p> : ''}</div>
          <button type="submit">
            {loading ? 'Submitting request...' : 'Submit Request'}
          </button>
        </fieldset>
      </OrderStyles>
    </div>
  );
}


export const query = graphql`
query {
	allAirtable(filter: {table: {eq: "Employees"}}) {
    edges {
      node {
        recordId
        table
        data {
          Name
        }
      }
    }
  }
}
`